export function vues(){
	const v = new Vue({
		el : '#appElem',
		data : {
			program : 'pool'
		},
		methods : {
			toggleCategory : function(el){
				this.program = el;
			}
		}
	});
}