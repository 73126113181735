export function faqs(){
    
	$(".FAQ .one").on("click",function(){
		if($(this).is(".active")){
			$(this).removeClass("active");
		}else{
			$(this).addClass("active");
		}
		$(this).find("dd").stop().slideToggle(200);
	});	

}