export function indexs(){
	
   //SWIPER
   const swiper = new Swiper('.s1', {
		loop: true,
		speed: 2000,
       slidesPerView: 'auto',
       spaceBetween: 30,
       pagination: {
           el: '.swiper-pagination',
           clickable: true,
       },
		autoplay: {
			delay: 4000,
			disableOnInteraction: true
		},
       breakpoints: {
           768:{
               slidesPerView: 1,
               spaceBetween: 0
           }
       }
   });

}

