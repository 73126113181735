export function facilitys(){
const swiper = new Swiper('.s2', {
    loop: true,
    speed: 800,
    slidesPerView: 2,
    centeredSlides : true,
    spaceBetween: 30,
	grabCursor:true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
	autoplay: {
			delay: 4000,
			disableOnInteraction: true
		},
            breakpoints: {
        768:{
            slidesPerView: 1,
            spaceBetween: 0
        }
    }
});
}

