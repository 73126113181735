import {vues} from './vues';
import {commons} from './common';
import {demos} from './demo';
import {faqs} from './faq';
import {indexs} from './index';
import {facilitys} from './facility';

$(() => {
	//vues();
    commons();
    demos();
    faqs();
    indexs();
	facilitys();
});